<template>
    <div>
        <UploadInventoryModal :close-modal="toggleImportInventoryModal" />
        <InventoryBulkUpdateModal :close-modal="toggleInventoryBulkUpdateModal" />
        <InventoryInventoryPriceUpdateModal :close-modal="toggleInventoryPriceUpdateModal" />
        <ReplicateProductsModal :close-modal="toggleReplicateProductModal" />
        <ServiceBulkUploadModal :closeModal="() => toggleServiceBulkImportModal(false)" />
        <VOnboardingWrapper ref="wrapper" :steps="steps" :options="options" />

        <div class="flex flex-wrap justify-between items-center w-90 center pv4-l pv3 mini-spacing">
            <div class="pb3 pb0-l">
                <div v-if="title !== 'Purchase Orders'">
                    <span class="f3 b" style="padding-right: 2px">{{ title }}</span>
                    <strong
                        ><font-awesome-icon
                            icon="fa-solid fa-question-circle fa-2xs"
                            class="text-muted clickModalIcon"
                            @click="clickModalInventory = true"
                            style="cursor: pointer; padding-bottom: 1px;"
                    /></strong>
                    <div class="dashboard-inquiry-modal" v-if="clickModalInventory">
                        This shows an overview of all your
                        {{ type !== 'SERVICE' ? 'inventory including recent sales' : 'service' }}. Click
                        <a
                            href="https://help.simplebks.com/en-gb/a/63e49a060c511b388155aa71-how-to-create-products"
                            target="_blank"
                            style="color: blue"
                            >here</a
                        >
                        to learn more.
                    </div>
                </div>
                <div v-if="title === 'Purchase Orders'">
                    <span class="f3 b" style="padding-right: 2px">{{ title }}</span>
                    <strong
                        ><font-awesome-icon
                            icon="fa-solid fa-question-circle fa-2xs"
                            class="text-muted clickModalIcon"
                            @click="clickModalInventory = true"
                            style="cursor: pointer; padding-bottom: 1px;"
                    /></strong>
                    <div class="dashboard-inquiry-modal" v-if="clickModalInventory">
                        A purchase order (PO) is a document that a buyer sends to a seller to request the purchase of goods or
                        services. It includes information about the items being purchased, the quantity, the price, the delivery
                        date, and the payment terms. The PO is a legally binding contract between the buyer and the seller.
                    </div>
                </div>
            </div>
            <div class="scrollmenu flex">
                <button
                    id="create-product-btn"
                    v-if="
                        (type === 'PRODUCT' || type === 'SERVICE') &&
                            (role === 'owner' ||
                                role === 'admin' ||
                                rolePermissions?.includes(32) ||
                                rolePermissions?.includes(33))
                    "
                    class="mr2"
                    @click="() => toggleCreateProduct({ show: true, cat: type })"
                    :disabled="emailVerifiedAt === null"
                    :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                >
                    Create New {{ type === 'SERVICE' ? 'service' : 'product' }}
                </button>
                <button
                    id="create-product-btn"
                    v-if="
                        type === 'STOCK_TRANSFER' &&
                            (role === 'owner' ||
                                role === 'admin' ||
                                rolePermissions?.includes(62))
                    "
                    class="mr2"
                    :disabled="emailVerifiedAt === null"
                    :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                >
                    <router-link :to="{ name: 'TransferStock' }">
                        Transfer Stock
                    </router-link>
                </button>
                <button
                    id="create-product-btn"
                    v-if="
                        type === 'PURCHASE_ORDER' &&
                            (role === 'owner' ||
                                role === 'admin' ||
                                rolePermissions?.includes(32) ||
                                rolePermissions?.includes(33))
                    "
                    class="mr2"
                    :disabled="emailVerifiedAt === null"
                    :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                >
                    <router-link :to="{ name: 'PurchaseOrderCreate' }" v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(57)">
                        Create Purchase Order
                    </router-link>
                </button>
                <button
                    id="create-product-btn"
                    v-if="type === 'PRODUCT_CATEGORY' &&
                            (role === 'owner' ||
                                role === 'admin' ||
                                rolePermissions?.includes(69))
                    "
                    class="mr2"
                    @click="() => onShowCreateCategory(true)"
                    :disabled="emailVerifiedAt === null"
                    :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                >
                    Create Category
                </button>
                <button
                    id="create-product-btn"
                    v-if="type === 'PRODUCT_BRAND' &&
                            (role === 'owner' ||
                                role === 'admin' ||
                                rolePermissions?.includes(73))
                    "
                    class="mr2"
                    @click="() => onShowCreateBrand(true)"
                    :disabled="emailVerifiedAt === null"
                    :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                >
                    Create Brand
                </button>
                <button
                    style="background-color: white; border: 1px solid #132C8C; color: #132C8C;"
                    class="dropdown-togglex "
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    More <font-awesome-icon icon="fa-solid fa-caret-down" class="fa-2x" />
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li
                        class="ml2"
                        style="cursor: pointer;"
                        v-if="type === 'PRODUCT' && (role === 'owner' || role === 'admin' || rolePermissions?.includes(54))"
                        @click.prevent="toggleInventoryBulkUpdateModal"
                        :disabled="emailVerifiedAt === null"
                        :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                    >
                        Bulk Update
                    </li>
                    <li
                        class="ml2"
                        style="cursor: pointer;"
                        v-if="type === 'PRODUCT' && (role === 'owner' || role === 'admin' || rolePermissions?.includes(54))"
                        @click.prevent="toggleInventoryPriceUpdateModal"
                        :disabled="emailVerifiedAt === null"
                        :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                    >
                        Price Update
                    </li>
                    <li
                        class="ml2"
                        style="cursor: pointer;"
                        v-if="type === 'PRODUCT' && (role === 'owner' || role === 'admin' || rolePermissions?.includes(54))"
                        @click.prevent="toggleReplicateProductModal"
                        :disabled="emailVerifiedAt === null"
                        :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                    >
                        Replicate Products
                    </li>
                    <li
                        class="ml2"
                        style="cursor: pointer;"
                        v-if="type === 'PRODUCT' && (role === 'owner' || role === 'admin' || rolePermissions?.includes(52))"
                        @click.prevent="toggleImportInventoryModal"
                        :disabled="emailVerifiedAt === null"
                        :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                    >
                        Import
                    </li>
                    <li
                        class="ml2"
                        style="cursor: pointer;"
                        v-if="type === 'SERVICE' && (role === 'owner' || role === 'admin' || rolePermissions?.includes(52))"
                        @click.prevent="toggleServiceBulkImportModal(true)"
                        :disabled="emailVerifiedAt === null"
                        :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                    >
                        Import
                    </li>
                    <li v-if="type === 'PRODUCT' && (role === 'owner' || role === 'admin' || rolePermissions?.includes(55))" class="ml2" style="cursor: pointer;" @click.prevent="downloadInventoryTemplate">Export</li>
                    <li>
                        <router-link :to="{ name: 'AddTransactionNew' }" @click="changeBusinessType('purchases')" class="ml2"
                            >Buy</router-link
                        >
                    </li>
                    <li v-if="type !== 'PRODUCT' && type !== 'SERVICE'">
                        <router-link :to="'#'" class="ml2" @click="() => toggleCreateProduct({ show: true, cat: type })">
                            Create New {{ type === 'SERVICE' ? 'service' : 'product' }}
                        </router-link>
                    </li>
                    <li v-if="state.hasMultiBusiness && type !== 'STOCK_TRANSFER'">
                        <router-link :to="{ name: 'TransferStock' }" class="ml2">Transfer Stock</router-link>
                    </li>
                    <li v-if="type !== 'PURCHASE_ORDER' && (role === 'owner' || role === 'admin' || rolePermissions?.includes(57))">
                        <router-link :to="{ name: 'PurchaseOrderCreate' }" class="ml2">Create Purchase Order</router-link>
                    </li>
                    <li v-if="type !== 'PRODUCT_CATEGORY'">
                        <router-link to="#" class="ml2" @click.prevent="onShowCreateCategory(true)">
                            Create Category
                        </router-link>
                    </li>
                    <li v-if="type !== 'PRODUCT_BRAND'">
                        <router-link to="#" class="ml2" @click.prevent="onShowCreateBrand(true)">
                            Create Brand
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>

        <!--inventory, import button, send statement & new -->
        <!--      Create Entity Modal / Form-->
        <CreateProduct :closeModal="() => toggleCreateProduct({ show: false, cat: type })" :transactionCategory="type" />

        <!-- summary all product, draft archieve -->
        <div class="w-90 center pv2">
            <div class="flex  flex-wrap justify-between items-center scrollmenu">
                <div class="flex">
                    <div v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(5)" class="mr4">
                        <router-link active-class="active-menu custom-active" :to="{ name: 'ProductsSummary' }"
                            >Summary</router-link
                        >
                    </div>
                    <div v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(6)" class="mr4">
                        <router-link active-class="active-menu custom-active" :to="{ name: 'Products' }"
                            >All Products</router-link
                        >
                    </div>
                    <div v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(9)" class="mr4">
                        <router-link active-class="active-menu custom-active" :to="{ name: 'Services' }"
                            >All Services</router-link
                        >
                    </div>
                    <div
                        v-if="state.hasMultiBusiness && (role === 'owner' || role === 'admin' || rolePermissions?.includes(61))"
                        class="mr4"
                    >
                        <router-link active-class="active-menu custom-active" :to="{ name: 'StockTransfers' }"
                            >Transfers</router-link
                        >
                    </div>
                    <div v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(56)" class="mr4">
                        <router-link active-class="active-menu custom-active" :to="{ name: 'PurchaseOrders' }"
                            >Purchase Orders</router-link
                        >
                    </div>
                    <div v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(69)" class="mr4">
                        <router-link active-class="active-menu custom-active" :to="{ name: 'ProductCategories' }"
                            >Categories</router-link
                        >
                    </div>
                    <div v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(73)" class="mr4">
                        <router-link active-class="active-menu custom-active" :to="{ name: 'ProductBrands' }"
                            >Product Brands</router-link
                        >
                    </div>
                    <!-- <div class="mr4">-->
                    <!--  <router-link active-class="active-menu custom-active" :to="{ name: 'ProductsAnalysis' }">Products Analysis</router-link>-->
                    <!--      </div>-->
                    <!-- <div class="mr4"><router-link active-menu="active-class custom-active" :to="{name: ''}">Draft</router-link></div>-->
                    <!-- <div class="mr4"><router-link active-menu="active-class custom-active" :to="{name: ''}">Archived</router-link></div>-->
                </div>
            </div>

            <div v-if="!$store.state.Alert?.showMobileMenu && ($route.name === 'Products' || $route.name === 'Services')" class="box-border-top box-border-bottom mb1 pv2">
                <div v-if="$route.name === 'Products'">
                    <div class="flex flex-wrap center items-center justify-between pb3">
                        <div class="flex flex-column items-start w-100 w-50-l">
                            <label class="b mv1">Search</label>
                            <SearchInput 
                                placeholder="Search products..." 
                                v-model="state.searchVal"
                                @change="updateRoute('q', state.searchVal)"
                            />
                        </div>
                        <div class="flex flex-column items-start w-100 w-25-l ph2">
                            <label class="b mv1">Category</label>
                            <select 
                                v-model="state.filter.category" 
                                class="w-100 filter-input"
                                @change="updateRoute('category', state.filter.category)"
                            >
                                <option value="">All </option>
                                <option v-for="category in categories" :key="category._id"  :value="category.name">
                                    {{ category.name }}
                                </option>
                            </select>
                        </div>
                        <div class="flex flex-column items-start w-100 w-25-l ph2">
                            <label class="b mv1">Brand</label>
                            <select 
                                v-model="state.filter.brand" 
                                class="w-100 filter-input"
                                @change="updateRoute('brand', state.filter.brand)"
                            >
                                <option value="">All </option>
                                <option v-for="brand in brands" :key="brand._id"  :value="brand.name">
                                    {{ brand.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="flex flex-wrap center items-center justify-between pb3">
                        <div class="flex flex-column items-start w-100 w-25-l ph2">
                            <label class="b mv1">Qty in stock</label>
                            <Slider 
                                v-model="state.filter.qty_in_stock" 
                                @change="updateRoute('quantity', state.filter.qty_in_stock)"
                                :min="0" 
                                :max="10000" 
                                class="w-100" 
                            />
                        </div>
                        <div class="flex flex-column items-start w-100 w-25-l ph2">
                            <label class="b mv1">Total sales</label>
                            <Slider 
                                v-model="state.filter.sales_cost"
                                @change="updateRoute('cost', state.filter.sales_cost)"
                                :min="0" 
                                :max="100000" 
                                class="w-100" 
                                :tooltipPosition="'down'"
                            />
                        </div>
                        <div class="flex flex-column items-start w-100 w-25-l ph2">
                            
                        </div>
                        <div class="w-100 w-25-l"></div>
                    </div>
                    <div class="flex flex-wrap center items-center justify-between pb3">
                        <div></div>
                        <button class="btn" @click="search">Search</button>
                    </div>
                </div>
                <div v-if="$route.name === 'Services'">
                    <SearchInput
                        placeholder="Search services..."
                        v-model="state.searchVal"
                        @update:modelValue="searchServices"
                    />
                </div>
            </div>

        </div>
        <!-- summary all product, draft archive -->
    </div>
</template>

<script>
import { computed, reactive, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
import CreateProduct from '@/components/CreateProduct'
import UploadInventoryModal from '@/components/UploadInventoryModal'
import InventoryBulkUpdateModal from './InventoryBulkUpdateModal'
import InventoryInventoryPriceUpdateModal from './InventoryInventoryPriceUpdateModal'
import ReplicateProductsModal from './ReplicateProductsModal'
import ServiceBulkUploadModal from './ServiceBulkUploadModal'
import SearchInput from 'vue-search-input'
import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css';
import Vue3TagsInput from 'vue3-tags-input'

export default {
    name: 'InventoryHeader',
    components: {
        CreateProduct,
        UploadInventoryModal,
        InventoryBulkUpdateModal,
        InventoryInventoryPriceUpdateModal,
        ReplicateProductsModal,
        SearchInput,
        ServiceBulkUploadModal,
        VOnboardingWrapper,
        Slider,
        Vue3TagsInput,
    },
    props: {
        page: String,
        type: String,
        title: String
    },
    setup(props, { emit }) {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const emailVerifiedAt = computed(() => store?.state?.Auth?.userData?.emailVerifiedAt)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const createProductTourStatus = computed(() => store.state?.Settings?.createProductTourStatus)
        const createServiceTourStatus = computed(() => store.state?.Settings?.createServiceTourStatus)
        const categories = computed(() => store.state?.Inventory?.categories.data)
        const brands = computed(() => store.state?.Inventory?.brands.data)
        const wrapper = ref(null)
        const { start } = useVOnboarding(wrapper)
        const orgData = computed(() => store?.state?.Auth?.userData)
        const staffList = computed(() => store.state.Settings.members)
        const options = {
            labels: {
                previousButton: 'Previous',
                nextButton: 'Next',
                finishButton: 'Finish'
            }
        }
        const steps = [
            {
                attachTo: { element: '#create-product-btn' },
                content: {
                    title: 'Create Product',
                    description:
                        props.type === 'PRODUCT'
                            ? 'Click this button to launch the create product modal'
                            : 'Click this button to launch the create service modal'
                },
                on: {
                    afterStep: async () => {
                        await store.dispatch('Inventory/showCreateProduct', { show: true, cat: props.type })
                    }
                }
            }
        ]

        const state = reactive({
            searchVal: '',
            filter: {
                category: '',
                brand: '',
                staff: '',
                qty_in_stock: [0, 0],
                sales_cost: [0, 0],
                tags: [],
            },
            tag: '',
            myTags: [],
            hasMultiBusiness: false
        })

        const toggleImportInventoryModal = () => {
            store.dispatch('Inventory/toggleImportInventoryModal')
        }

        const toggleInventoryBulkUpdateModal = () => {
            store.dispatch('Inventory/toggleInventoryBulkUpdateModal')
        }

        const toggleInventoryPriceUpdateModal = () => {
            store.dispatch('Inventory/toggleInventoryPriceUpdateModal')
        }

        const toggleReplicateProductModal = () => {
            store.dispatch('Inventory/toggleReplicateProductModal')
        }

        const toggleServiceBulkImportModal = status => {
            store.dispatch('Inventory/toggleServiceBulkImportModal', status)
        }

        const toggleCreateProduct = payload => {
            store.dispatch('Inventory/showCreateProduct', payload)
        }

        const onShowCreateCategory = (payload = true) => {
            store.dispatch('Inventory/showCreateCategory', payload)
        }

        const onShowCreateBrand = (payload = true) => {
            store.dispatch('Inventory/showCreateBrand', payload)
        }

        const handleSelectedTag = (tag) => {
            state.filter.tags.push(tag);
            state.myTags = state.myTags.filter(myTag => myTag.text !== tag.text);
        }

        const handleChangeTag = (tags) => {
            state.filter.tags = tags;
            // state.myTags.push(tags);
        }

        const search = () => {
            const tags = state.filter.tags.map(tag => tag.text)
            const query = {
                filterType: 'search',
                limit: route.query?.limit || 25,
                page: route.query?.page || 1,
                q: state.searchVal,
                filterByCategory: state.filter.category,
                filterByBrand: state.filter.brand,
                filterByQuantity: state.filter.qty_in_stock,
                filterByCost: state.filter.sales_cost,
                filterByTags: tags,
            }
            emit('on-search', query)
        }

        const searchServices = () => {
            const query = {
                limit: route.query.limit || 25,
                page: route.query.page || 1,
                filterType: 'search',
                q: state.searchVal
            }
            store.dispatch('Inventory/getAllServices', query)
        }

        const changeBusinessType = type => {
            // console.log(type, 'type')
            store.dispatch('DashboardStats/getBusinessType', type)
        }
        const clickModalInventory = ref(false)
        const closeModal = () => {
            clickModalInventory.value = false
        }

        const startCreateProductTour = () => {
            if (createProductTourStatus.value) {
                start()
            }
        }

        const startCreateServiceTour = () => {
            if (createServiceTourStatus.value) {
                start()
            }
        }
        const downloadInventoryTemplate = () => {
            store.dispatch('Inventory/downloadInventoryTemplate').then(response => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = `${orgData.value?.org}-inventory-template.csv`
                fileLink.setAttribute('download', fileName)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
                // state.downloading = false
            })
        }

        const updateRoute = (field, value) => {
            const query = route.query
            if (field === 'quantity') {
                const from = state.filter.qty_in_stock[0]
                const to = state.filter.qty_in_stock[1]
                value = `${from},${to}`
            }
            if (field === 'cost') {
                const from = state.filter.sales_cost[0]
                const to = state.filter.qty_in_stsales_costock[1]
                value = `${from},${to}`
            }
            router.replace({
                name: 'Products',
                query: { ...query, [field]: value }
            });
        }

        onMounted(() => {
            store.dispatch('Settings/getBusinesses', true).then(resp => {
                if (resp.status) {
                    state.hasMultiBusiness = resp.data.length > 1
                }
            })
            store.dispatch('Inventory/getAllCategories')
            store.dispatch('Inventory/getAllBrands')
            store.dispatch('Settings/getTeamMembers')
            store.dispatch('Tag/getTags').then(data => {
                if (data.status) {
                    state.myTags = data?.data || []
                }
            })
            
            state.filter.category = route.query?.category || '',
            state.filter.brand = route.query?.brand || '',
            state.filter.quantity = route.query?.quantity || '',
            state.filter.cost = route.query?.cost || '',
            // state.filter.filterByTags = route.query?.tags.split(',') || [],

            startCreateProductTour()
            startCreateServiceTour()
            window.addEventListener('click', event => {
                const modalIcon = document.querySelector('.clickModalIcon')

                if (
                    !(event.target.closest('.clickModalIcon') || event.target === modalIcon) &&
                    clickModalInventory.value === true
                ) {
                    closeModal()
                }
            })
        })
        return {
            state,
            search,
            searchServices,
            toggleCreateProduct,
            toggleImportInventoryModal,
            toggleInventoryBulkUpdateModal,
            toggleInventoryPriceUpdateModal,
            toggleReplicateProductModal,
            emailVerifiedAt,
            rolePermissions,
            role,
            changeBusinessType,
            toggleServiceBulkImportModal,
            clickModalInventory,
            closeModal,
            wrapper,
            steps,
            options,
            downloadInventoryTemplate,
            staffList,
            handleSelectedTag,
            handleChangeTag,
            categories,
            brands,
            onShowCreateCategory,
            onShowCreateBrand,
            updateRoute,
        }
    }
}
</script>

<style>
:root {
  --v-calendar-datepicker-icon-color:#132c8c;
  --v-calendar-action-color:#132c8c;
  --v-calendar-day-hover-bg-color:#febf12;
  --v-calendar-active-bg-color:#132c8c;
  --v-calendar-range-bg-color:#fffceb;
  /* --v-calendar-range-text-color:#febf12; */
}

.search-input-wrapper input[data-search-input=true] {
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #e3e8ee;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
}

.search-input-wrapper .search-icon.shortcut {
    width: 0;
    height: 0;
    cursor: text;
    right: 0;
    bottom: 0;
    background-color: #e7eff7;
    border-radius: 3px;
    z-index: 50;
}

.search-input-wrapper .search-icon.shortcut::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 2px;
    transform: rotate(25deg);
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    z-index: 51;
    background-color: #96b9dc;
}

.v-calendar {
    width: 100% !important;
    min-width: 100% !important;
}

.v-calendar .input-field.long {
    min-width: 100% !important;
}

.v-calendar .input-field input {
    width: 100% !important;
    height: 35px;
    padding-left: 30px;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #e3e8ee;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
}

.filter-input {
    padding: 8px 5px;
    /* font-size: 15px;
    font-weight: 400; */
}

.v3ti {
    width: 100%;
    border: 1px solid #e3e8ee;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.v3ti .v3ti-content--select {
    padding-right: unset;
}

.v3ti .v3ti-new-tag {
    padding: 8px 5px;
}

.hlyi45ju8 button {
    padding: 0 5px;
}

.custom-active {
    /* color: #132c8c; */
    color: #132c8c;
    display: inline-block;
}

.slider-connect {
    background: #132c8c;
}
.slider-tooltip {
    background: #febf12;
    border: 1px solid #febf12;
}
</style>
