<template>
    <div v-if="showReplicateProductsModal">
        <div class="jquery-modal blocker current" @click="closeModalOnOutsideClick" ref="modalRef">
            <div id="modal-bulk" class="modal-target2">
                <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                            <div class="flex flex-column pb3">
                                <label class="pb2 font-w2 tl">Source Business</label>
                                <select
                                    class="form-sign"
                                    v-model="state.sourceBusiness"
                                    name="sourceBusiness"
                                    id="sourceBusiness"
                                    style="padding: 10px"
                                    required
                                >
                                    <option value="">Select source business</option>
                                    <option v-for="business in state.businesses" :key="business._id" :value="business.org">
                                        {{ business.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="flex flex-column pb3">
                                <label class="pb2 font-w2 tl">Destination Business(es)</label>
                                <vue3-tags-input
                                    v-model:tags="state.selectedBusinesses"
                                    v-model="state.business"
                                    :select="true"
                                    :select-items="state.destinationBusinesses"
                                    @on-select="handleSelectedTag"
                                    @on-tags-changed="handleChangeTag"
                                    placeholder="Select business"
                                    required
                                >
                                    <template #item="{ tag }">
                                        {{ tag.text }}
                                    </template>
                                    <template #no-data>
                                        No business
                                    </template>
                                    <template #select-item="tag">
                                        {{ tag.text }}
                                    </template>
                                </vue3-tags-input>
                            </div>
                            <button :disabled="state.buttonDisabled">
                                {{ state.buttonDisabled ? 'Processing...' : 'Submit' }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import Vue3TagsInput from 'vue3-tags-input'

export default {
    name: 'ReplicateProductsModal',
    props: {
        closeModal: Function,
    },
    components: { Vue3TagsInput },

    setup(props, { emit }) {
        const store = useStore()
        const userData = computed(() => store?.state?.Auth?.userData)
        const showReplicateProductsModal = computed(() => store.state?.Inventory?.showReplicateProductsModal)

        const state = reactive({
            buttonDisabled: false,
            businesses: [],
            allBusinesses: [],
            sourceBusiness: '',
            selectedBusinesses: [],
            business: '',

        })

        const modalRef = ref(null)

        const closeModalOnOutsideClick = (e) => {
            if (e.target === modalRef.value) {
                props.closeModal()
            }
        }

        const onSubmit = () => {
            state.buttonDisabled = true
            const selectedBusinesses = state.selectedBusinesses.map(business => business.id)
            const payload = {
                sourceBusiness: state.sourceBusiness,
                destinationBusinesses: selectedBusinesses
            }
            store.dispatch('Inventory/replicateProductsinBusinesses', payload).then((resp) => {
                if (resp) {
                    state.selectedBusinesses = []
                    setBusinesses()
                    props.closeModal()
                    state.buttonDisabled = false
                }
            })
        }

        const updateIncomingState = (incomingState, key) => {
            state[incomingState] = state[key]
        }

        const handleSelectedTag = (tag) => {
            state.selectedBusinesses.push(tag);
            state.destinationBusinesses = state.destinationBusinesses.filter(myTag => myTag.text !== tag.text);
        }

        const handleChangeTag = (tags) => {
            state.selectedBusinesses = tags;
        }

        const setBusinesses = () => {
             store.dispatch('Settings/getBusinesses', true).then(resp => {
                if (resp.status) {
                    state.businesses = resp.data
                    state.allBusinesses = resp.data
                    state.destinationBusinesses = state.businesses.map(business => {
                        return { id: business.org, text: business.name }
                    })
                    state.sourceBusiness = userData.value?.orgId || ''
                }
            })
        }

        onMounted(() => {
            setBusinesses()
        })

        return {
            state,
            emit,
            updateIncomingState,
            modalRef,
            closeModalOnOutsideClick,
            showReplicateProductsModal,
            handleSelectedTag,
            handleChangeTag,
            onSubmit,
        }
    },
}
</script>

<style scoped></style>
