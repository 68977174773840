<template>
    <div v-if="showServiceBulkUploadModal">
        <div class="jquery-modal blocker current" @click="closeModalOnOutsideClick" ref="importRef">
            <div id="modal-bulk" class="modal-target2">
                <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <h3>Service Bulk Upload</h3>
                        <div class="whiteBgModal">
                            <div class="lh-copy">Use this to update multiple services at the same time by following the
                                steps below
                            </div>
                            <ol>
                                <li>Download the <a href="" @click.prevent="downloadServiceTemplate"
                                        class="b text-info">template</a> here.</li>
                                <li>Add the name, service charge, category and brand of the services you want to add
                                </li>
                                <li>Click choose file and select the file you just edited in CSV format
                                </li>
                                <li>Select Upload to add your services</li>
                            </ol>
                            <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                                <input type="file" name="imageFile" id="file" class="product-input"
                                    @change="onFileChange" accept="text/csv" required />
                                <button :disabled="state.buttonDisabled">
                                    {{ state.buttonDisabled ? 'Uploading...' : 'Upload' }}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { computed, reactive, ref } from 'vue'
    import { useStore } from 'vuex'

    export default {
        name: 'ServiceBulkUploadModal',
        props: {
            closeModal: Function,
        },

        setup(props, { emit }) {
            const store = useStore()

            const showServiceBulkUploadModal = computed(() => store.state?.Inventory?.showServiceBulkUploadModal)

            const state = reactive({
                buttonDisabled: false,
                imageFile: null,
            })

            const importRef = ref(null)

            const onFileChange = (e) => {
                state.imageFile = e.target.files?.[0]
            }

            const closeModalOnOutsideClick = (e) => {
                if (e.target === importRef.value) {
                    props.closeModal()
                }
            }

            const downloadServiceTemplate = () => {
                store.dispatch('Inventory/downloadServiceTemplate').then((response) => {
                    if (response?.data?.error) {
                        console.error(response.data.error)
                    }

                    const fileURL = window.URL.createObjectURL(new Blob([response]))
                    const fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    const fileName = `service-bulk-upload-template.csv`
                    fileLink.setAttribute('download', fileName)
                    fileLink.setAttribute('target', '_blank')
                    document.body.appendChild(fileLink)
                    fileLink.click()
                    fileLink.remove()
                    // state.downloading = false
                })
            }

            const onSubmit = () => {
                state.buttonDisabled = true
                const formData = new FormData()
                formData.append('imageFile', state.imageFile)
                store.dispatch('Inventory/importServiceBulkUpload', formData).then((resp) => {
                    if (resp) {
                        props.closeModal()
                        state.imageFile = null
                        state.buttonDisabled = false
                    }
                })
            }

            const updateIncomingState = (incomingState, key) => {
                state[incomingState] = state[key]
            }

            return {
                state,
                emit,
                updateIncomingState,
                importRef,
                closeModalOnOutsideClick,
                downloadServiceTemplate,
                onFileChange,
                showServiceBulkUploadModal,
                onSubmit,
            }
        },
    }
</script>

<style scoped></style>