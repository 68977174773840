<template>
    <div v-if="showInventoryPriceUpdateModal">
        <div class="jquery-modal blocker current" @click="closeModalOnOutsideClick" ref="importRef">
            <div id="modal-bulk" class="modal-target2">
                <!-- <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <h3>Inventory Bulk Update</h3>
                        <div class="lh-copy">
                            Use this to update multiple inventories at the same time. Download the <a href=""
                                @click.prevent="downloadInventoryTemplate" class="b text-info">template</a> here, update
                            as much inventory as you want, and upload the file in a CSV format.

                        </div>
                        <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                            <input type="file" name="imageFile" id="file" class="product-input" @change="onFileChange"
                                accept="text/csv" required />
                            <button :disabled="state.buttonDisabled">
                                {{ state.buttonDisabled ? 'Uploading...' : 'Upload Template' }}
                            </button>
                        </form>
                    </div>
                </div> -->
                <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <h3>Inventory Price Update</h3>
                        <div class="whiteBgModal">
                            <div class="lh-copy">
                                Here's a simple step-by-step guide on how to update product price on Simplebks in bulk:
                            </div>
                            <ol>
                                <li>
                                    Download the product list
                                    <a href="" @click.prevent="downloadInventoryTemplate" class="b text-info">here</a>:This is a
                                    file that has information about all your products in CSV (It's like a table that you can open
                                    in programs like Microsoft Excel)
                                </li>
                                <li>
                                    Upload the file without making any changes
                                </li>
                                <li>
                                    Update your inventory: Look for an option that says "Update Inventory" and click on it. This
                                    will process the file you uploaded and update your inventory with the new product information
                                </li>
                            </ol>
                            <div>That's it! Now you've successfully updated the product information on SimpleBks in bulk</div>
                        </div>
                        <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                            <input
                                type="file"
                                name="imageFile"
                                id="file"
                                class="product-input"
                                @change="onFileChange"
                                accept="text/csv"
                                required
                            />
                            <div class="mv2">
                                <div class="tl font-bold">Buying price change</div>
                                <div class="flex">
                                    <input
                                        type="number"
                                        v-model="state.buyingPriceValue"
                                        class="product-input"
                                        required
                                    />
                                    <select v-model="state.buyingPriceIndex" class="product-input" required>
                                        <option value="fixed">Fixed</option>
                                        <option value="percentage">Percentage</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mv2">
                                <div class="tl font-bold">Selling price change</div>
                                <div class="flex">
                                    <input
                                        type="number"
                                        v-model="state.sellingPriceValue"
                                        class="product-input"
                                        required
                                    />
                                    <select v-model="state.sellingPriceIndex" class="product-input" required>
                                        <option value="fixed">Fixed</option>
                                        <option value="percentage">Percentage</option>
                                    </select>
                                </div>
                            </div>
                            <button :disabled="!state.imageFile">
                                {{ state.buttonDisabled ? 'Uploading...' : 'Update Inventory' }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'InventoryPriceUpdateModal',
    props: {
        closeModal: Function,
    },

    setup(props, { emit }) {
        const store = useStore()

        const orgData = computed(() => store?.state?.Auth?.userData)
        const showInventoryPriceUpdateModal = computed(() => store.state?.Inventory?.showInventoryPriceUpdateModal)

        const state = reactive({
            buttonDisabled: false,
            imageFile: null,
            buyingPriceIndex: 'fixed',
            sellingPriceIndex: 'fixed',
            buyingPriceValue: 0,
            sellingPriceValue: 0,
        })

        const importRef = ref(null)

        const onFileChange = (e) => {
            state.imageFile = e.target.files?.[0]
        }

        const closeModalOnOutsideClick = (e) => {
            if (e.target === importRef.value) {
                props.closeModal()
            }
        }

        const downloadInventoryTemplate = () => {
            store.dispatch('Inventory/downloadInventoryTemplate').then((response) => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = `${orgData.value?.org}-inventory-template.csv`
                fileLink.setAttribute('download', fileName)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
                // state.downloading = false
            })
        }

        const onSubmit = () => {
            if (!state.imageFile) {
                return
            }
            state.buttonDisabled = true
            const formData = new FormData()
            formData.append('buyingPriceIndex', state.buyingPriceIndex)
            formData.append('buyingPriceValue', state.buyingPriceValue)
            formData.append('sellingPriceIndex', state.sellingPriceIndex)
            formData.append('sellingPriceValue', state.sellingPriceValue)
            formData.append('imageFile', state.imageFile)

            store.dispatch('Inventory/inventoryPriceUpdate', formData).then((resp) => {
                if (resp) {
                    props.closeModal()
                    state.imageFile = null
                    state.buyingPriceIndex = 'fixed'
                    state.sellingPriceIndex = 'fixed'
                    state.buyingPriceValue = 0
                    state.sellingPriceValue = 0
                    state.buttonDisabled = false
                }
            })
        }

        const updateIncomingState = (incomingState, key) => {
            state[incomingState] = state[key]
        }

        return {
            state,
            emit,
            updateIncomingState,
            importRef,
            closeModalOnOutsideClick,
            downloadInventoryTemplate,
            onFileChange,
            showInventoryPriceUpdateModal,
            onSubmit,
        }
    },
}
</script>

<style scoped></style>
